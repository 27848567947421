import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material'; // Import the CircularProgress component
import '@kitware/vtk.js/favicon';
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import '@kitware/vtk.js/Rendering/Profiles/Glyph';

import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkConeSource from '@kitware/vtk.js/Filters/Sources/ConeSource';
import vtkGlyph3DMapper from '@kitware/vtk.js/Rendering/Core/Glyph3DMapper';
import vtkAppendPolyData from '@kitware/vtk.js/Filters/General/AppendPolyData';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkSTLReader from '@kitware/vtk.js/IO/Geometry/STLReader';
import vtkOBJReader from '@kitware/vtk.js/IO/Misc/OBJReader'; // Import OBJReader for OBJ file support
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkPlaneSource from '@kitware/vtk.js/Filters/Sources/PlaneSource';
import { ColorMode, ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import vtkScalarBarActor from '@kitware/vtk.js/Rendering/Core/ScalarBarActor';
import vtkOpenGLHardwareSelector from '@kitware/vtk.js/Rendering/OpenGL/HardwareSelector';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import useFetchPositions from '../hooks/useFetchPositions';
import useFetchDirections from '../hooks/useFetchDirections';
import ControlPanel from './ControlPanel';
import useFetchAndCacheData from '../hooks/useFetchAndCacheData';
import PointInspector from './PointInspector';
import CustomInteractorStyle from './CustomInteractorStyle'; // Import the custom interactor style
import { getSiteById, HEIGHTS } from '../config/siteConfig';

// Helper function to determine file type from URL
const getFileType = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  switch (extension) {
    case 'stl':
      return 'stl';
    case 'obj':
      return 'obj';
    default:
      console.warn(`Unknown file extension: ${extension}, defaulting to STL`);
      return 'stl';
  }
};

function WindVisualizer({ windSpeedRange = [0, 10] }) {

    const [height, setHeight] = useState(20);
    const [time, setTime] = useState(0);
    const [siteIndex, setSiteIndex] = useState(0);
    const [cachedData, setCachedData] = useState({});
    const [positions, setPositions] = useState([]);
    const [directions, setDirections] = useState([]);
    const [firstRendered, setFirstRendered] = useState(false);
    const [loading, setLoading] = useState(true);

    // For picking
    const [selectedPoint, setSelectedPoint] = useState(null); // { x, y, z, index? }
    const [inspectorOpen, setInspectorOpen] = useState(false);
    const [timeSeriesData, setTimeSeriesData] = useState([]);    // For table
    const [heightSeriesData, setHeightSeriesData] = useState([]); // For chart

    // Add performance logging
    const perfLogs = useRef({
        start: performance.now(),
        lastLog: performance.now()
    });

    const logPerformance = (label) => {
        const now = performance.now();
        const sinceLast = now - perfLogs.current.lastLog;
        const sinceStart = now - perfLogs.current.start;
        console.log(`[Performance] ${label}:`);
        console.log(`  - Time since last log: ${sinceLast.toFixed(2)}ms`);
        console.log(`  - Time since start: ${sinceStart.toFixed(2)}ms`);
        perfLogs.current.lastLog = now;
    };
    // Add to loading effect
    useEffect(() => {
        // Early return if any required data is missing
        if (!positions || !directions) return;

        // Check if data is loaded and ready
        if (loading &&
            positions.length > 0 &&
            directions.length > 0 &&
            firstRendered) {
            logPerformance('All data loaded');
            console.log("done loading");
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }

    }, [positions, directions, time, firstRendered, loading]);


    const rendererRef = useRef(null);
    const rendererWindowRef = useRef(null);
    const loadedModelRef = useRef(false);
    const loadedModelsRef = useRef([]);

    // Add new ref for scalar actors
    const scalarActorsRef = useRef([]);

    // For hardware picking
    const hardwareSelectorRef = useRef(null);
    // We also need the container for screen coords
    const containerRef = useRef(null);

    const highlightSphereRef = useRef(null);

    useFetchPositions(siteIndex, height, setPositions, cachedData);
    useFetchDirections(siteIndex, height, time, setDirections, cachedData);
    useFetchAndCacheData(cachedData, setCachedData);

    // Helper: Return the container DOM element used by vtkFullScreenRenderWindow
    const getContainerElement = () => {
        // By default, the fullScreenRenderer attaches the container to <body> or #root,
        // but it also creates a <div id="vtk-container">. We can store that ref.
        return containerRef.current || document.querySelector('#vtk-container');
    };

    // Attach a sphere to highlight the picked point
    const highlightPickedPoint = (worldPos) => {
        const renderer = rendererRef.current;
        const renderWindow = rendererWindowRef.current;

        if (!highlightSphereRef.current) {
            // Create sphere
            const sphereSource = vtkSphereSource.newInstance({ radius: 5 }); // tune radius
            const sphereMapper = vtkMapper.newInstance();
            sphereMapper.setInputConnection(sphereSource.getOutputPort());

            const sphereActor = vtkActor.newInstance();
            sphereActor.setMapper(sphereMapper);
            sphereActor.getProperty().setColor(1.0, 0.2, 0.2); // highlight color
            renderer.addActor(sphereActor);
            highlightSphereRef.current = sphereActor;
        }
        // Update sphere position
        if (highlightSphereRef.current) {
            highlightSphereRef.current.setPosition(worldPos[0], worldPos[1], worldPos[2]);
            renderWindow.render();
        }
    };
    function resetView() {
        if (!rendererRef.current) return;
        const camera = rendererRef.current.getActiveCamera();
        camera.set({ position: [0, 0, 10000], viewUp: [0, 1, 0], focalPoint: [0, 0, 0] });
        rendererRef.current.resetCamera();
        rendererWindowRef.current?.render();
    }
    
    useEffect(() => {
        const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
            background: [0.2, 0.3, 0.4],
            containerStyle: { height: '100vh', width: '100vw', position: 'relative' },
        });
        rendererRef.current = fullScreenRenderer.getRenderer();
        rendererWindowRef.current = fullScreenRenderer.getRenderWindow();

        // Keep track of container in a ref
        containerRef.current = fullScreenRenderer.getContainer();

        // Set up hardware selector
        const selector = vtkOpenGLHardwareSelector.newInstance({
            captureZValues: true,
        });
        selector.attach(fullScreenRenderer.getApiSpecificRenderWindow(), rendererRef.current);
        hardwareSelectorRef.current = selector;

        // **Apply the custom interactor style**
        const customInteractorStyle = CustomInteractorStyle();
        fullScreenRenderer.getInteractor().setInteractorStyle(customInteractorStyle);

        // Basic camera setup
        resetView();

        return () => {
            // Clean up if necessary
        };
    }, []);
    
    
  // Load 3D geometry whenever siteIndex changes
  useEffect(() => {
    logPerformance('Starting 3D model load');
    loadedModelRef.current = false;

    // Get the current site configuration
    const currentSite = getSiteById(siteIndex);
    if (!currentSite) {
        console.error(`Site with index ${siteIndex} not found`);
        return;
    }

    // Create internal caches for Mappers/Actors so we only create them once
    const modelMappers = new Map();
    const modelActors = new Map();

    // Clean up previous model actors
    loadedModelsRef.current.forEach((actor) => {
      rendererRef.current.removeActor(actor);
    });
    rendererWindowRef.current.render();
    loadedModelsRef.current = [];

    const loadModel = async (url, position) => {
      const startTime = performance.now();
      const fileType = getFileType(url);
      let reader;
      
      // Create the appropriate reader based on file type
      if (fileType === 'obj') {
        reader = vtkOBJReader.newInstance();
      } else { // Default to STL
        reader = vtkSTLReader.newInstance();
      }

      // Create mapper/actor if needed
      if (!modelMappers.has(url)) {
        const mapper = vtkMapper.newInstance();
        const actor = vtkActor.newInstance();
        mapper.setScalarVisibility(false);
        actor.setMapper(mapper);
        loadedModelsRef.current.push(actor);
        modelMappers.set(url, mapper);
        modelActors.set(url, actor);
      }

      try {
        await reader.setUrl(url);
        const mapper = modelMappers.get(url);
        mapper.setInputConnection(reader.getOutputPort());
        const actor = modelActors.get(url);

        // Just position the actor origin if needed
        actor.setPosition(0, 0, 0);

        rendererRef.current.addActor(actor);
        rendererWindowRef.current.render();
        resetView();

        const totalTime = performance.now() - startTime;
        console.log(`Total ${fileType.toUpperCase()} processing time for ${url}: ${totalTime.toFixed(2)}ms`);
        logPerformance(`Loaded ${fileType.toUpperCase()}: ${url}`);
        return true;
      } catch (error) {
        console.error(`Error loading ${url}:`, error);
        return false;
      }
    };

    // First load the terrain and lowest LOD (lo0) for immediate display
    const loadInitialModels = async () => {
      try {
        await Promise.all([
          loadModel(currentSite.modelFiles.terrain, [0, 0, -1]),
          loadModel(currentSite.modelFiles.buildings[0], [0, 0, 0])
        ]);
        
        // Mark as initially loaded so the UI can be responsive
        loadedModelRef.current = true;
        rendererWindowRef.current.render();
        
        // Then load higher LOD models progressively
        loadHigherLODModels();
      } catch (error) {
        console.error("Error loading initial models:", error);
      }
    };
    
    // Load higher LOD models after initial display
    const loadHigherLODModels = async () => {
      try {
        // Load remaining LOD levels sequentially
        for (let i = 1; i < currentSite.modelFiles.buildings.length; i++) {
            await loadModel(currentSite.modelFiles.buildings[i], [0, 0, 0]);
            rendererWindowRef.current.render();
        }
        
        logPerformance('All LOD models loaded');
      } catch (error) {
        console.error("Error loading higher LOD models:", error);
      }
    };
    
    // Start the progressive loading process
    loadInitialModels();
    
  }, [siteIndex]); // Must keep
    // Right-click picking only (disable left click)
    useEffect(() => {
        const containerEl = getContainerElement();
        if (!containerEl) return;

        const handleContextMenu = (evt) => {
            evt.preventDefault(); // avoid default context menu
            evt.stopPropagation();
            performHardwarePick(evt);
        };

        // Only add the contextmenu event listener (right-click)
        containerEl.addEventListener('contextmenu', handleContextMenu);
        
        return () => {
            containerEl.removeEventListener('contextmenu', handleContextMenu);
        };
    }, [positions, directions]);

    const performHardwarePick = (evt) => {
        console.log("Performing hardware pick");
        const containerEl = getContainerElement();
        const bounds = containerEl.getBoundingClientRect();
        const [canvasWidth, canvasHeight] = rendererWindowRef.current.getViews()[0].getSize();
        const view = rendererWindowRef.current.getViews()[0];
        const renderer = rendererRef.current;

        // Translate mouse event to canvas coords
        const scaleX = canvasWidth / bounds.width;
        const scaleY = canvasHeight / bounds.height;

        const x = scaleX * (evt.clientX - bounds.left);
        const y = scaleY * (bounds.height - (evt.clientY - bounds.top));

        // Try hardware selector first (works well in Firefox)
        const selector = hardwareSelectorRef.current;
        if (!selector) {
            console.error("Hardware selector not available");
            return;
        }

        // A small picking region around the mouse
        const tolerance = 5;
        const x1 = Math.floor(x - tolerance);
        const y1 = Math.floor(y - tolerance);
        const x2 = Math.ceil(x + tolerance);
        const y2 = Math.ceil(y + tolerance);

        selector.setArea(x1, y1, x2, y2);
        const buffersAvailable = selector.captureBuffers();
        console.log("Buffers available:", buffersAvailable);
        
        if (buffersAvailable) {
            const pos = [x, y];
            const outSelectedPosition = [0, 0];
            const info = selector.getPixelInformation(pos, tolerance, outSelectedPosition);
            
            console.log("Pixel information:", info);
            
            if (info && info.displayPosition) {
                // ALTERNATIVE APPROACH: Ray-plane intersection
                // Get the camera and compute ray origin and direction
                const camera = renderer.getActiveCamera();
                const cameraPos = camera.getPosition();
                
                // Convert screen point to world space direction
                const worldPoint1 = view.displayToWorld(
                    info.displayPosition[0], 
                    info.displayPosition[1], 
                    0.0, // Near plane
                    renderer
                );
                
                const worldPoint2 = view.displayToWorld(
                    info.displayPosition[0], 
                    info.displayPosition[1], 
                    1.0, // Far plane
                    renderer
                );
                
                // Compute ray direction
                const rayDir = [
                    worldPoint2[0] - worldPoint1[0],
                    worldPoint2[1] - worldPoint1[1],
                    worldPoint2[2] - worldPoint1[2]
                ];
                
                // Normalize direction
                const length = Math.sqrt(rayDir[0]*rayDir[0] + rayDir[1]*rayDir[1] + rayDir[2]*rayDir[2]);
                rayDir[0] /= length;
                rayDir[1] /= length;
                rayDir[2] /= length;
                
                // Compute intersection with z=height plane
                // Ray-plane intersection: t = (planeZ - rayOrigin.z) / rayDirection.z
                const t = (height - worldPoint1[2]) / rayDir[2];
                
                // Calculate the raw intersection point
                let intersectionPoint = [
                    worldPoint1[0] + t * rayDir[0],
                    worldPoint1[1] + t * rayDir[1],
                    height
                ];
                
                // Get the plane bounds from the positions array
                const minX = Math.min(...positions.map(pos => pos[0]));
                const maxX = Math.max(...positions.map(pos => pos[0]));
                const minY = Math.min(...positions.map(pos => pos[1]));
                const maxY = Math.max(...positions.map(pos => pos[1]));
                
                // Check if the point is outside the plane bounds
                const outsideBounds = 
                    intersectionPoint[0] < minX || 
                    intersectionPoint[0] > maxX || 
                    intersectionPoint[1] < minY || 
                    intersectionPoint[1] > maxY;
                
                if (outsideBounds) {
                    console.log("Point outside data plane bounds, snapping to closest edge");
                    
                    // Constrain to the bounds of the plane
                    intersectionPoint[0] = Math.max(minX, Math.min(maxX, intersectionPoint[0]));
                    intersectionPoint[1] = Math.max(minY, Math.min(maxY, intersectionPoint[1]));
                }
                
                console.log("Final point for selection:", intersectionPoint);
                
                // Now find the closest data point
                const pointIdx = findClosestPointIndex(intersectionPoint, positions);
                
                if (pointIdx >= 0) {
                    // Get the actual position of the selected data point
                    const actualPosition = [
                        positions[pointIdx][0],
                        positions[pointIdx][1],
                        height
                    ];
                    
                    const picked = {
                        x: actualPosition[0],
                        y: actualPosition[1],
                        z: height,
                        index: pointIdx,
                    };

                    // Highlight the actual data point, not the intersection
                    highlightPickedPoint(actualPosition);
                    // Highlight the point
                    highlightPickedPoint(intersectionPoint);

                    setSelectedPoint(picked);

                    // Build data series
                    const newTimeSeries = buildTimeSeries(siteIndex, height, pointIdx);
                    setTimeSeriesData(newTimeSeries);
                    const newHeightSeries = buildHeightSeries(siteIndex, pointIdx);
                    setHeightSeriesData(newHeightSeries);

                    console.log("Setting inspector open to true");
                    setInspectorOpen(true);
                    
                    // Force re-render in Chrome
                    setTimeout(() => {
                        rendererWindowRef.current.render();
                    }, 0);
                    
                    return; // Exit early on success
                }
            }
            
            console.log("No valid target found in pick area");
        } else {
            console.error("Failed to capture buffers");
        }
    };

    // Build time-based info for the selected point
    // This is an example that uses `directions` across times 0..5
    // In your real code, you might fetch from cachedData if needed.
    const buildTimeSeries = (siteIdx, h, pointIdx) => {
        if (!cachedData || !cachedData[siteIdx] || !cachedData[siteIdx][h]) {
            console.log("No data found for siteIdx: ", siteIdx, " and height: ", h);
            return []; // No data
        }

        const result = [];

        for (let t = 0; t <= 5; t++) {
            const dataEntry = cachedData[siteIdx][h][t];
            console.log(`Checking data for time ${siteIdx} ${h} ${t}:`, dataEntry);
            if (!dataEntry || !dataEntry.directions) {
                // fallback or empty
                console.log(`No data found for time ${t}`);
                result.push({ time: t, speed: 0, directionDeg: 0 });
                continue;
            }

            // directions is a flat array. Each point has 3 components: [vx, vy, vz]
            // So the point's components start at pointIdx * 3
            console.log("Data Entry: ", dataEntry);
            const dirs = dataEntry.directions;
            const vx = dirs[pointIdx][0];
            const vy = dirs[pointIdx][1];
            const vz = dirs[pointIdx][2];

            console.log("vx: ", vx, "vy: ", vy, "vz: ", vz);
            
            // Speed in 2D or 3D? If you only care about horizontal magnitude, omit vz.
            const speed = Math.sqrt(vx * vx + vy * vy + vz * vz);
            if(Math.random() > 0.9){
                console.log("Speed: ", speed);
            }
            // Convert vector components to meteorological wind direction
            // Wind direction is the direction the wind is coming FROM
            // Meteorological convention: 0° is North, 90° is East, etc.
            let directionDeg = Math.atan2(-vx, -vy) * (180 / Math.PI);
            directionDeg = (directionDeg + 360) % 360; // keep in [0, 360)
            directionDeg += 180;
            result.push({
                time: t,
                speed,
                directionDeg,
            });
        }

        return result;
    };

    // Build height-based data
    const buildHeightSeries = (siteIdx, pointIdx) => {
        const result = [];

        for (let h of HEIGHTS) {
            // Use current time
            const t = time;

            if (!cachedData[siteIdx] || !cachedData[siteIdx][h] || !cachedData[siteIdx][h][t]) {
                result.push({ height: h, speed: 0, directionDeg: 0 });
                continue;
            }

            const dirs = cachedData[siteIdx][h][t].directions;
            if (!dirs || !dirs[pointIdx]) {
                result.push({ height: h, speed: 0, directionDeg: 0 });
                continue;
            }

            // Correctly access the direction components using array indexing
            const vx = dirs[pointIdx][0];
            const vy = dirs[pointIdx][1];
            const vz = dirs[pointIdx][2];

            const speed = Math.sqrt(vx * vx + vy * vy + vz * vz);
            // Use same direction conversion as above
            let directionDeg = Math.atan2(-vx, -vy) * (180 / Math.PI);
            directionDeg = (directionDeg + 360) % 360;

            result.push({ height: h, speed, directionDeg });
        }

        return result;
    };

    function findClosestPointIndex(worldPos, positions) {
        if (!positions || positions.length === 0) return -1;

        // positions might be 2D array or 1D array. Adjust logic accordingly; for example:
        // If positions is an array of arrays [ [x1, y1, z1], [x2, y2, z2], ... ]
        // or if it's flattened [x1, y1, z1, x2, y2, z2, ...]

        // Example if positions is array-of-arrays:
        let minIndex = 0;
        let minDistSqr = Infinity;
        for (let i = 0; i < positions.length; i++) {
            // positions[i] = [x, y, z]
            const dx = positions[i][0] - worldPos[0];
            const dy = positions[i][1] - worldPos[1];
            const dz = positions[i][2] - worldPos[2];
            const distSqr = dx * dx + dy * dy + dz * dz;
            if (distSqr < minDistSqr) {
                minDistSqr = distSqr;
                minIndex = i;
            }
        }
        return minIndex;
    }

    useEffect(() => {
        if (!positions || !directions || positions.length === 0 || directions.length === 0) {
            if (positions && positions.length > 0) {
                console.log("loaded Positions data, count: ", positions.length);
                logPerformance('loaded Positions data');
            }
            if (directions) {
                logPerformance('loaded Directions data');
            }
            logPerformance('no data loaded');
            console.log("no data loaded");
            return;
        }

        // Clean up previous scalar actors using the ref
        scalarActorsRef.current.forEach(actor => {
            console.log("Removing scalar actor");
            rendererRef.current.removeActor(actor);
        });
        rendererWindowRef.current.render();
        scalarActorsRef.current = []; // Clear the ref array

        logPerformance('Starting visualization update');

        // Data flattening
        logPerformance('Starting data flattening');
        const flatPositions = positions.flat();
        const flatDirections = directions.flat();
        logPerformance('Finished data flattening');

        const renderer = rendererRef.current;
        const renderWindow = rendererWindowRef.current;

        // VTK object creation
        logPerformance('Starting VTK object creation');
        const polyData = vtkPolyData.newInstance();
        const appendFilter = vtkAppendPolyData.newInstance();
        logPerformance('Finished VTK object creation');

        // Points processing
        logPerformance('Starting points processing');
        if (flatPositions.length % 3 === 0) {
            const points = new Float32Array(flatPositions.map((value, index) =>
                index % 3 === 2 ? height : value));
            polyData.getPoints().setData(points, 3);
        }
        logPerformance('Finished points processing');

        // Directions processing
        logPerformance('Starting directions processing');
        if (flatDirections.length % 3 === 0) {
            const orientationArray = vtkDataArray.newInstance({
                numberOfComponents: 3,
                values: flatDirections,
                name: 'flatDirections',
            });
            polyData.getPointData().setVectors(orientationArray);

            // Magnitude calculations
            logPerformance('Starting magnitude calculations');
            const magnitudes = new Float32Array(flatDirections.length / 3);
            for (let i = 0; i < flatDirections.length; i += 3) {
                const vx = flatDirections[i];
                const vy = flatDirections[i + 1];
                const vz = flatDirections[i + 2];
                magnitudes[i / 3] = Math.sqrt(vx * vx + vy * vy + vz * vz);
            }
            logPerformance('Finished magnitude calculations');

            const magnitudeArray = vtkDataArray.newInstance({
                numberOfComponents: 1,
                values: magnitudes,
                name: 'magnitude',
            });
            polyData.getPointData().addArray(magnitudeArray);
            polyData.getPointData().getArrayByName('magnitude').setRange(windSpeedRange[0], windSpeedRange[1]);
            polyData.getPointData().setActiveScalars('magnitude');
        }
        logPerformance('Finished directions processing');

        // Mapper and actor setup
        logPerformance('Starting mapper setup');
        appendFilter.setInputData(polyData);

        const mapper = vtkGlyph3DMapper.newInstance();
        const actor = vtkActor.newInstance();

        const coneSource = vtkConeSource.newInstance();
        coneSource.setResolution(12);
        coneSource.setHeight(3);
        coneSource.setRadius(0.2);

        mapper.setInputConnection(appendFilter.getOutputPort());
        mapper.setSourceConnection(coneSource.getOutputPort());
        mapper.setScaleFactor(5);
        mapper.setScaleModeToScaleByConstant();
        mapper.setOrientationModeToDirection();
        mapper.setOrientationArray('flatDirections');
        logPerformance('Finished mapper setup');

        // Color setup
        logPerformance('Starting color setup');
        const orientationArray = vtkDataArray.newInstance({
            numberOfComponents: 3,
            values: flatDirections.map((value, index) => (index % 3 === 2 ? 0 : value)),
            name: 'flatDirections',
        });
        polyData.getPointData().setVectors(orientationArray);

        const colorTransferFunction = vtkColorTransferFunction.newInstance();
        colorTransferFunction.addRGBPoint(windSpeedRange[0], 0.0, 0.0, 1.0);
        colorTransferFunction.addRGBPoint(windSpeedRange[1] / 2, 0.0, 1.0, 0.0);
        colorTransferFunction.addRGBPoint(windSpeedRange[1], 1.0, 0.0, 0.0);
        mapper.setLookupTable(colorTransferFunction);
        mapper.setScalarVisibility(false);
        logPerformance('Finished color setup');

        // Scalar plane setup
        logPerformance('Starting scalar plane setup');
        actor.setMapper(mapper);
        renderer.addActor(actor);
        scalarActorsRef.current.push(actor);
        let planeActor = null;
        let scalarBarActor = null;

        // Calculate resolution based on aspect ratio
        const calculateResolution = (width, depth, baseResolution = 320) => {
            if (width === 0 || depth === 0) {
                return { resolutionX: baseResolution, resolutionY: baseResolution }; // Avoid division by zero
            }

            const aspectRatio = width / depth;
            let resolutionX, resolutionY;

            if (aspectRatio >= 1) {
                resolutionX = baseResolution;
                resolutionY = Math.max(1, Math.floor(baseResolution / aspectRatio));
            } else {
                resolutionY = baseResolution;
                resolutionX = Math.max(1, Math.floor(baseResolution * aspectRatio));
            }

            return { resolutionX, resolutionY };
        };

        const addScalarPlane = () => {
            const point1 = [flatPositions[0], flatPositions[1], height];
            const point2 = [flatPositions[flatPositions.length - 3], flatPositions[flatPositions.length - 2], height];

            // Calculate the width and depth for proper scaling
            const width = Math.abs(point2[0] - point1[0]);
            const depth = Math.abs(point2[1] - point1[1]);
            console.log("WIDTH: ", width);
            console.log("DEPTH: ", depth);
            const resolutionScale = 1; // You can adjust this based on your needs

            const { resolutionX, resolutionY } = calculateResolution(width, depth, 320 * resolutionScale);
            console.log("RESOLUTION X: ", resolutionX);
            console.log("RESOLUTION Y: ", resolutionY);
            const planeSource = vtkPlaneSource.newInstance({
                xResolution: resolutionX,
                yResolution: resolutionY,
                origin: point1,
                point1: [point2[0], point1[1], height],  // Set x2,y1,height for point1
                point2: [point1[0], point2[1], height]   // Set x1,y2,height for point2
            });

            const windSpeeds = polyData.getPointData().getArrayByName('magnitude').getData();

            const vtkWindDataArray = vtkDataArray.newInstance({
                name: 'magnitude',
                values: windSpeeds,
                numberOfComponents: 1,
            });

            planeSource.getOutputData().getPointData().addArray(vtkWindDataArray, 1);
            planeSource.getOutputData().getPointData().setActiveScalars('magnitude');

            planeSource.getOutputData().getCellData().addArray(vtkWindDataArray, 1);
            planeSource.getOutputData().getCellData().setActiveScalars('magnitude');

            const planeMapper = vtkMapper.newInstance();
            planeMapper.setInputConnection(planeSource.getOutputPort());

            planeMapper.set({
                interpolateScalarsBeforeMapping: true,
                colorMode: ColorMode.DEFAULT,
                scalarMode: ScalarMode.DEFAULT,
                useLookupTableScalarRange: true,
                scalarVisibility: true,
            });

            const lookupTable = vtkColorTransferFunction.newInstance();
            lookupTable.addRGBPoint(0, 0.0, 0.0, 1.0);
            lookupTable.addRGBPoint(1.5, 0.0, 1.0, 1.0);
            lookupTable.addRGBPoint(3, 0.0, 1.0, 0.0);
            lookupTable.addRGBPoint(4.5, 1.0, 1.0, 0.0);
            lookupTable.addRGBPoint(6, 1.0, 0.0, 0.0);

            lookupTable.setMappingRange(windSpeedRange[0], windSpeedRange[1]);
            planeMapper.setLookupTable(lookupTable);

            planeActor = vtkActor.newInstance();
            planeActor.getProperty().setPointSize(2);
            planeActor.getProperty().setInterpolationToFlat();
            planeActor.setMapper(planeMapper);

            renderer.addActor(planeActor);

            scalarBarActor = vtkScalarBarActor.newInstance();
            renderer.addActor(scalarBarActor);
            scalarBarActor.setScalarsToColors(lookupTable);
            scalarBarActor.setAxisLabel('Wind Speed [m/s]');

            scalarActorsRef.current.push(planeActor);
            scalarActorsRef.current.push(scalarBarActor);
            return planeActor;
        };

        addScalarPlane();
        logPerformance('Finished scalar plane setup');

        // Final render
        logPerformance('Starting render');
        renderWindow.render();
        if (!firstRendered) {
            resetView();
            setFirstRendered(true);
            logPerformance('First render complete');
        }
        logPerformance('Finished render');

    }, [siteIndex, positions, directions, time, windSpeedRange, height]);

    // Add keyboard event listener
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'p' || event.key === 'P') {
                let dict = {};

                console.log("=== Directions Data ===");
                for (let i = 0; i < directions.length; i++) {
                    let hash = directions[i] + "";
                    if (dict[hash] === undefined) {
                        dict[hash] = 1;
                    } else {
                        dict[hash]++;
                    }
                }

                //Remove all keys with value 1
                for (let key in dict) {
                    if (dict[key] === 1) {
                        delete dict[key];
                    }
                }
                console.log(dict);
            }
        };

        window.addEventListener('keypress', handleKeyPress);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [directions]); // Add directions as dependency to ensure we have latest data

    // Add useEffect to monitor inspector state changes
    useEffect(() => {
        console.log("Inspector open state changed to:", inspectorOpen);
    }, [inspectorOpen]);

    return (
        <>
            {loading && (
                <div className="loading-overlay">
                    Loading... <span className="loading-icon"><CircularProgress className="fast-spinner" /></span>
                </div>
            )}
            <ControlPanel setHeight={setHeight} setTime={setTime} resetView={resetView} setLoading={setLoading} setSiteIndex={setSiteIndex} />
            <div id="vtk-container" />
            <PointInspector
                open={inspectorOpen}
                onClose={() => setInspectorOpen(false)}
                pickedPointData={selectedPoint}
                timeSeries={timeSeriesData}
                heightSeries={heightSeriesData}
                style={{ zIndex: 1000 }}
            />
        </>
    );
}

export default WindVisualizer;