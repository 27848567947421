import vtkInteractorStyleManipulator from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator';
import InteractionPresets from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator/Presets';

function CustomInteractorStyle() {
    const interactorStyle = vtkInteractorStyleManipulator.newInstance();

    // Define custom interaction definitions
    const interactorStyleDefinitions = [
        { type: 'pan', options: { button: 1 } }, // Pan on Left button drag
        { type: 'zoom', options: { button: 1, control: true } }, // Zoom on Ctrl + Left button drag
        { type: 'zoom', options: { dragEnabled: false, scrollEnabled: true } }, // Zoom on scroll
    ];

    // Apply the custom interaction definitions
    InteractionPresets.applyDefinitions(interactorStyleDefinitions, interactorStyle);

    return interactorStyle;
}

export default CustomInteractorStyle; 