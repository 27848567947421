/**
 * Site Configuration
 * This file contains configuration for all sites in the application.
 * Add new sites by adding entries to the SITES array.
 * Supports both STL and OBJ file formats - file extension will be automatically detected.
 */

// Available heights for all sites
export const HEIGHTS = [20, 50, 100, 150, 200];

// Maximum forecast time (in hours)
export const MAX_FORECAST_TIME = 5;

// API endpoints
export const API_ENDPOINTS = {
  development: 'http://localhost:5000',
  production: 'https://server.urbanwindhk.com'
};

// Site configurations
export const SITES = [
  {
    id: 0,
    name: 'Tsim Sha Tsui',
    description: 'Tsim Sha Tsui wind simulation site',
    // 3D model files for different LOD levels (supports .stl and .obj formats)
    modelFiles: {
      terrain: 'city/terrain_s0.stl',
      buildings: [
        'city/buildings_s0_lo0.stl', // Lowest detail (fastest to load)
        'city/buildings_s0_lo1.stl', // Medium detail
        'city/buildings_s0_lo2.stl',  // Highest detail
      ]
    },
    // Default view settings
    defaultView: {
      position: [0, 0, 500],
      focalPoint: [0, 0, 0]
    }
  },
  {
    id: 1,
    name: 'Mong Kok',
    description: 'Secondary wind simulation site',
    // 3D model files - showing mixed STL and OBJ usage example
    modelFiles: {
      terrain: 'city/terrain_s1.stl',
      buildings: [
        'city/buildings_s1_lo0.stl', // Lowest detail (fastest to load)
        'city/buildings_s1_lo1.stl', // Medium detail
        'city/buildings_s1_lo2.obj'  // Highest detail - using OBJ format
      ]
    },
    // Default view settings
    defaultView: {
      position: [0, 0, 500],
      focalPoint: [0, 0, 0]
    }
  },
  {
    id: 2,
    name: 'Central',
    description: 'Central wind simulation site',
    // 3D model files (supports .stl and .obj formats)
    modelFiles: {
      terrain: 'city/terrain_s2.stl',
      buildings: [
        'city/buildings_s2_lo0.stl', // Lowest detail (fastest to load)
        'city/buildings_s2_lo1.stl', // Medium detail
        'city/buildings_s2_lo2.stl'  // Highest detail
      ]
    },
    // Default view settings
    defaultView: {
      position: [0, 0, 500],
      focalPoint: [0, 0, 0]
    }
  },
  {
    id: 3,
    name: 'HKSTP',
    description: 'HKSTP wind simulation site',
    // 3D model files (supports .stl and .obj formats)
    modelFiles: {
      terrain: 'city/terrain_s3.stl',
      buildings: [
        'city/buildings_s3_lo0.stl', // Lowest detail (fastest to load)
        'city/buildings_s3_lo1.stl', // Medium detail
        'city/buildings_s3_lo2.stl'  // Highest detail
      ]
    },
    // Default view settings
    defaultView: {
      position: [0, 0, 500],
      focalPoint: [0, 0, 0]
    }
  },
  {
    id: 4,
    name: 'Causeway Bay',
    description: 'Causeway Bay wind simulation site',
    // 3D model files (supports .stl and .obj formats)
    modelFiles: {
      terrain: 'city/terrain_s4.stl',
      buildings: [
        'city/buildings_s4_lo0.stl', // Lowest detail (fastest to load)
        'city/buildings_s4_lo1.stl', // Medium detail
        'city/buildings_s4_lo2.stl'  // Highest detail
      ]
    },
    // Default view settings
    defaultView: {
      position: [0, 0, 500],
      focalPoint: [0, 0, 0]
    }
  },
  {
    id: 5,
    name: 'HKU',
    description: 'HKU wind simulation site',
    // 3D model files (supports .stl and .obj formats)
    modelFiles: {
      terrain: 'city/terrain_s5.stl',
      buildings: [
        'city/buildings_s5_lo0.stl', // Lowest detail (fastest to load)
        'city/buildings_s5_lo1.stl', // Medium detail
        'city/buildings_s5_lo2.stl'  // Highest detail
      ]
    },
    // Default view settings
    defaultView: {
      position: [0, 0, 500],
      focalPoint: [0, 0, 0]
    }
  }
  // Add more sites here as needed
];

/**
 * Get site by ID
 * @param {number} id - Site ID
 * @returns {Object|null} Site object or null if not found
 */
export const getSiteById = (id) => {
  return SITES.find(site => site.id === id) || null;
};

/**
 * Get API base URL based on current environment
 * @returns {string} API base URL
 */
export const getApiBaseUrl = () => {
  return process.env.NODE_ENV === 'development'
    ? API_ENDPOINTS.development
    : API_ENDPOINTS.production;
};

export default {
  SITES,
  HEIGHTS,
  MAX_FORECAST_TIME,
  getSiteById,
  getApiBaseUrl
}; 