import React from 'react';
import { Drawer, IconButton, Typography, Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Example chart with Recharts
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

function ArrowDirection({ angle }) {
  // angle in degrees (0 = along +x, or some convention).
  // We can rotate a simple "▲" or another symbol, or an SVG arrow, etc.
  // Here we do a simple character in a <div> that rotates with CSS.
  const arrowStyle = {
    display: 'inline-block',
    transform: `rotate(${angle}deg)`,
    transition: 'transform 0.3s',
    fontSize: '1.2rem',
  };
  return <span style={arrowStyle}>↑</span>;
}

export default function PointInspector({
  open,
  onClose,
  pickedPointData,
  timeSeries,
  heightSeries,
}) {
  /*
    pickedPointData: { x, y, z, index, siteIndex }
    timeSeries: Array of objects, e.g. [
      { time: 0, speed: 2.3, directionDeg: 45 },
      { time: 1, speed: 2.9, directionDeg: 90 },
      ...
    ]
    heightSeries: Array of objects for chart, e.g. [
      { height: 10, speed: 2.3 },
      { height: 20, speed: 3.1 },
      ...
    ]
  */

  // Interpolate speed at height 0
  const interpolateSpeedAtHeight0 = (series) => {
    if (series.length < 2) return series;
    const [first, second] = series;
    return [{ height: 0, speed: 0 }, ...series];
  };

  const updatedHeightSeries = interpolateSpeedAtHeight0(heightSeries.map(item => ({
    ...item,
    speed: parseFloat(item.speed.toFixed(2))
  })));

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 300, padding: 2 } }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Inspector</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box>

      {/* Basic debugging info */}
      {pickedPointData && (
        <Box mb={2}>
          <Typography variant="body2">
            <strong>Position:</strong> {`(${pickedPointData.x.toFixed(2)}, ${pickedPointData.y.toFixed(2)}, ${pickedPointData.z.toFixed(2)})`}
          </Typography>
          <Typography variant="body2">
            <strong>Index:</strong> {pickedPointData.index}
          </Typography>
        </Box>
      )}

      {/* Time vs Speed/Direction Table */}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Time (h)</TableCell>
            <TableCell>Speed (m/s)</TableCell>
            <TableCell>Dir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeSeries.map((item) => (
            <TableRow key={item.time}>
              <TableCell>{item.time}</TableCell>
              <TableCell>{item.speed.toFixed(1)}</TableCell>
              <TableCell>
                <ArrowDirection angle={item.directionDeg} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Time vs Speed chart */}
      <Box height={200} mt={2}>
        <Typography variant="subtitle2" gutterBottom>
          Wind Speed vs Time
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={timeSeries}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="time" 
              label={{ value: 'Time (h)', position: 'insideBottomRight', offset: 0 }} 
            />
            <YAxis 
              label={{ value: 'Speed (m/s)', angle: -90, position: 'insideLeft' }} 
            />
            <Tooltip formatter={(value) => value.toFixed(2)} />
            <Line type="monotone" dataKey="speed" stroke="#82ca9d" dot={true} label={({ value }) => `speed: ${value.toFixed(2)}`} />
          </LineChart>
        </ResponsiveContainer>
      </Box>

      {/* "Wind Speed vs Height" chart */}
      <Box height={200} mt={2}>
        <Typography variant="subtitle2" gutterBottom>
          Wind Speed Profile
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={updatedHeightSeries} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              type="number"
              label={{ value: 'Speed (m/s)', position: 'insideBottom', offset: -5 }} 
            />
            <YAxis 
              dataKey="height" 
              type="number"
              label={{ value: 'Height (m)', angle: -90, position: 'insideLeft' }}
              domain={[0, 'dataMax']}
              reversed={true}
            />
            <Tooltip />
            <Line type="monotone" dataKey="speed" stroke="#8884d8" dot={true} label={({ value }) => value.toFixed(1)} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Drawer>
  );
} 