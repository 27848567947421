import { useEffect } from "react";
import { SITES, HEIGHTS, MAX_FORECAST_TIME, getApiBaseUrl } from "../config/siteConfig";

/**
 * This is a hook that fetches and caches data from the server
 * @param {Object} cachedData - Current cached data
 * @param {Function} setCachedData - Function to update cached data
 */
function useFetchAndCacheData(cachedData, setCachedData) {
    useEffect(() => {
        const fetchDataSequentially = async () => {
            // Object to accumulate all data
            let allData = {};
            const apiBaseUrl = getApiBaseUrl();

            // Fetch data for each site
            for (const site of SITES) {
                const siteIndex = site.id;
                allData[siteIndex] = {};
                
                // First, fetch positions if not cached
                if (cachedData[siteIndex]?.positions === undefined) {
                    try {
                        const url = `${apiBaseUrl}/positions/${siteIndex}`;
                        
                        const positionsResponse = await fetch(url);
                        const positionsData = await positionsResponse.json();
                        console.log(`Positions count for site ${siteIndex}: ${positionsData.positions.length}`);
                        allData[siteIndex].positions = positionsData.positions;
                    } catch (error) {
                        console.error(`Error fetching positions for site ${siteIndex}:`, error);
                    }
                }

                // Fetch data for each height
                for (const height of HEIGHTS) {
                    if (cachedData?.[siteIndex]?.[height] !== undefined) {
                        console.log(`Already Cached for site ${siteIndex}, height ${height}`);
                        continue;
                    }
                    
                    console.log(`Loading data for site ${siteIndex}, height ${height}...`);
                    allData[siteIndex][height] = {};

                    // Fetch data for each forecast time
                    try {
                        for (let forecastTime = 0; forecastTime <= MAX_FORECAST_TIME; forecastTime++) {
                            const timeUrl = `${apiBaseUrl}/data/${siteIndex}/${height}/${forecastTime}`;
                            
                            const timeResponse = await fetch(timeUrl);
                            if (!timeResponse.ok) throw new Error(`HTTP error! status: ${timeResponse.status}`);
                            const data = await timeResponse.json();
                            allData[siteIndex][height][forecastTime] = { directions: data.directions };

                            console.log(`Loaded data for site ${siteIndex}, height ${height}, time ${forecastTime}`);

                            // Update cache with first batch of data to improve perceived performance
                            if(siteIndex === SITES[0].id && height === HEIGHTS[0] && forecastTime === 0){
                                console.log(`First Load Set Cached Data: ${data.directions.length} directions`);
                                setCachedData(allData);
                            }
                        }
                    } catch (error) {
                        console.error(`Error fetching data for site ${siteIndex}, height ${height}:`, error);
                    }
                }
            }

            // Finally, update all cached data at once
            setCachedData(prev => ({
                ...prev,
                ...allData
            }));
        };

        fetchDataSequentially();
    }, []);
}

export default useFetchAndCacheData;