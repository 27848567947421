import { useEffect } from "react";

/**
 * Hook to fetch position data for a specific site
 * @param {number} siteIndex - Index of the site
 * @param {number} height - Height value
 * @param {function} setPositions - Function to set positions
 * @param {object} cachedData - Cached data object
 */
function useFetchPositions(siteIndex, height, setPositions, cachedData) {
    useEffect(() => {
        // If we have cached data, use it
        if (cachedData && 
            cachedData[siteIndex]) {
            console.log(`Using cached positions for site ${siteIndex}`);
            setPositions(cachedData[siteIndex].positions);
            return;
        }
    }, [siteIndex, height, cachedData, setPositions]);
}

export default useFetchPositions;