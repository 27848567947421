import { useEffect } from "react";

/**
 * Hook to fetch direction data for a specific site, height, and time
 * @param {number} siteIndex - Index of the site
 * @param {number} height - Height value
 * @param {number} time - Time value
 * @param {function} setDirections - Function to set directions
 * @param {object} cachedData - Cached data object
 */
function useFetchDirections(siteIndex, height, time, setDirections, cachedData) {
    useEffect(() => {
        // Check if cachedData and its nested properties exist before accessing
        if(cachedData === undefined){
            console.log("cachedData is undefined");
            return;
        }
        if(cachedData[siteIndex] === undefined){
            console.log(`cachedData for site ${siteIndex} is undefined`);
            return;
        }
        if(cachedData[siteIndex][height] === undefined){
            console.log(`cachedData for site ${siteIndex}, height ${height} is undefined`);
            return;
        }
        if(cachedData[siteIndex][height][time] === undefined){
            console.log(`cachedData for site ${siteIndex}, height ${height}, time ${time} is undefined`);
            return;
        }
        console.log(`Using cached directions for site ${siteIndex}, height ${height}, time ${time}`);
        setDirections(cachedData[siteIndex][height][time].directions);
    }, [siteIndex, height, time, cachedData, setDirections]);
}

export default useFetchDirections;